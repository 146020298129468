import { Link as ChakraLink, Button, Flex, Heading, Text } from '@chakra-ui/react'
import { FC } from 'react'

import { getEnv } from '@/utils/get-env'

export type Props = {
  login: () => Promise<void>
}

export const LoginForm: FC<Props> = ({ login }) => (
  <Flex direction="column" align="center" justify="center" h="100vh">
    <Flex
      direction="column"
      align="center"
      justify="center"
      boxShadow="md"
      borderRadius="md"
      mb={8}
      py={8}
      px={16}
    >
      <Heading
        whiteSpace="pre-wrap"
        textAlign="center"
        mb={8}
      >{`AGAROOT ACADEMY \nfor Biz`}</Heading>

      <Button colorScheme="teal" onClick={login} mb={4}>
        ログイン
      </Button>
      <Text>外部サービスに遷移します</Text>
    </Flex>

    <ChakraLink href={getEnv().NEXT_PUBLIC_CONTACT_URL} isExternal>
      お問い合わせはこちら
    </ChakraLink>
  </Flex>
)
